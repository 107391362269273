@font-face {
  font-family: 'Open Sans', sans-serif;
  src: url('./openSansRegular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  src: url(./openSansBold.ttf);
  font-weight: 700;
}
